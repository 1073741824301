<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <template v-if="!loading">
      <b-card-header>
        <div>
          <b-card-title> {{$t('dash.cards.new_subscriptions')}}</b-card-title>
          <b-card-text class="font-small-2">
            {{$t('dash.cards.last_month')}}
          </b-card-text>
        </div>
        <!-- <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item href="#">
            Last 28 Days
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Month
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Year
          </b-dropdown-item>
        </b-dropdown> -->
        
      </b-card-header>

      <b-card-body>
        <div
          v-for="transaction in transactions"
          :key="transaction.payment_method_id"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :src="require('@/assets/images/payment_methods/'+transaction.payment_method_id+'.png')"
                variant="light"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ transaction.payment_method_id }}
              </h6>
              <small>{{ transaction.payment_method_id }}</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder text-success"
          >
            {{ transaction.total_count }}
          </div>
        </div>
      </b-card-body>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BSpinner,BCardTitle, BCardBody, BCardText,
  BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,BSpinner,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  data(){
    return {
      loading: false,
      transactions:[],
    }
  },
  created() {
    this.getTransactions()
  },
  methods:{
    async getTransactions(){
      this.loading = true
      await this.$http.post('dashboard/transactions',{}).then(response => {
        console.log(response.data, "transactions!!!!")
        this.transactions = response.data.payments
        
        this.loading = false
      }) 
    }
  }
}
</script>
