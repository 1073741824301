<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="4"
        md="12"
      >
        <welcome-card />
      </b-col>
      <b-col
        lg="8"
        md="12"
      >
        <statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="12"
      >
      <schools-table />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import WelcomeCard from './cards/WelcomeCard.vue'
import Statistics from './cards/Statistics.vue'
import SchoolsTable from './cards/SchoolsTable.vue'
export default {
  components:{
    WelcomeCard,
    Statistics,
    SchoolsTable,

    BRow, BCol

  }
}
</script>

<style>

</style>