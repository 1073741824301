<template>
  <section id="dashboard-analytics"  >
    <b-row class="match-height">
      <b-col
        lg="4"
        md="12"
      >
        <welcome-card />
      </b-col>
      <b-col
        lg="8"
        md="12"
      >
        <statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="4"
        md="12"
      >
        <usage-by-school context="group"/>
      </b-col>

      <b-col
        lg="4"
        md="12"
      >
        <usage-by-school context="dreamer"/>
      </b-col>
       <b-col
        lg="4"
        md="6"
      >
        <contents-states context="school" />
      </b-col>
    </b-row>
  </section>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import WelcomeCard from './cards/WelcomeCard.vue'
import Statistics from './cards/Statistics.vue'
import UsageBySchool from './cards/UsageBySchool.vue'
import ContentsStates from './cards/ContentsStates.vue'

export default {
  components:{
    WelcomeCard,
    Statistics,
    UsageBySchool,
    ContentsStates,

    BRow, BCol
  },
}
</script>

<style>

</style>