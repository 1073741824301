<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <template v-if="!loading">
      <b-card-header>
        <b-card-title>{{$t('dash.statistics_for')}} {{ subject || 'Smile and Learn' }}</b-card-title>
      <!--  <b-card-text class="font-small-2 mr-25 mb-0">
          Updated 1 month ago
        </b-card-text> -->
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statistics"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2 mt-1"
              >
                <b-link
                  :to="{ name: item.route, 
                    params: {id: item.params}
                  }"
                >
                  <b-avatar
                    size="48"
                    :variant="item.color"
                  >
                    <feather-icon
                      size="24"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </b-link>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,BSpinner, BLink, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BLink, BSpinner,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data(){
    return {
      statistics: null,
      subject:null, 
      loading: false,
    }
  },
  mounted(){
    this.getStatistics()
  },
  methods:{
    async getStatistics(){
      this.loading = true
      await this.$http.post('dashboard',{}).then( response =>{
        //console.log(response)
        this.subject = response.data.subject
        //this.statistics = response.data.statistics
        this.loading = false

        let items = []
        for (var [key, value] of Object.entries(response.data.statistics)) {
            switch (key) {
              case 'partners':
                items.push({
                  icon: 'BriefcaseIcon',
                  color: 'light-primary',
                  title: value,
                  subtitle: 'Partners',
                  customClass: 'mb-2 mb-xl-0',
                  route: 'partners',
                })
                break;
              case 'schools':
                items.push({
                  icon: 'BookIcon',
                  color: 'light-warning',
                  title: value,
                  subtitle: this.$t('dash.statistics.active_schools'),
                  customClass: 'mb-2 mb-xl-0',
                  route: 'schools',
                })
                break;
              case 'licences':
                items.push({
                  icon: 'UserIcon',
                  color: 'light-success',
                  title: value,
                  subtitle: this.$t('dash.statistics.active_licences'),
                  customClass: 'mb-2 mb-sm-0',
                  route: 'licences',
                })
                break;
              case 'dreamers':
                items.push({
                  icon: 'SmileIcon',
                  color: 'light-success',
                  title: value,
                  subtitle: this.$t('Dreamers'),
                  customClass: '',
                  route: 'dreamers',
                })
                break;
              case 'admin_licences':
                items.push({
                  icon: 'BriefcaseIcon',
                  color: 'light-primary',
                  title: value,
                  subtitle: this.$t('dash.statistics.active_admin_licences'),
                  customClass: 'mb-2 mb-xl-0',
                  route: 'licences',
                })
                break;
              case 'inactive_licences':
                items.push({
                  icon: 'UserMinusIcon',
                  color: 'light-danger',
                  title: value,
                  subtitle: this.$t('dash.statistics.inactive_licences'),
                  customClass: 'mb-2 mb-xl-0',
                  route: 'licences',
                })
                break;
              case 'dreamers_amount':
                items.push({
                  icon: 'SmileIcon',
                  color: 'light-secondary',
                  title: value,
                  subtitle: this.$t('Dreamers'),
                  customClass: '',
                  route: 'school-dreamers-list',
                  params:  response.data.school
                })
                break;
              case 'groups_amount':
                items.push({
                  icon: 'UsersIcon',
                  color: 'light-info',
                  title: value,
                  subtitle: this.$t('dash.statistics.groups'),
                  customClass: '',
                  route: 'school-groups-list',
                  params:  response.data.school
                })
                break;
              case 'learning_paths_amount':
                items.push({
                  icon: 'CompassIcon',
                  color: 'light-success',
                  title: value,
                  subtitle: this.$t('dash.statistics.learning_paths'),
                  customClass: '',
                  route: 'learning-paths',
                })
                break;
              case 'total_usage':
                items.push({
                  icon: 'ClockIcon',
                  color: 'light-warning',
                  title: value,
                  subtitle: this.$t('dash.statistics.total_usage'),
                  customClass: '',
                  route: 'main-home',
                })
                break;
              default:
                break;
            }
        }        
        this.statistics = items
      }).catch(error => {
          console.log("Err -> ", error)
      })
    },
  }
}
</script>
