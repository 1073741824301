<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="90"
      class="shadow mb-2"
      :src="require('@/assets/images/avatar/AvatarSprite_57.png?v4')"
    />
    <h1 class="mb-1 mt-50 text-white">
      {{ $t('dash.greeting', {name: userData.fullName || userData.username}) }}
    </h1>
    <!-- <b-card-text class="m-auto w-75">
      Tienes  <strong>{{ data.saleToday }}</strong> Rutas de Aprendizaje recomendadas.
    </b-card-text> -->
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  data(){
    return {
      userData: this.$store.getters['user/getUserData']
    }
  }
}
</script>
