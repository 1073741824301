<template>
   <b-card no-body>
    <template v-if="!loading">
      <b-card-header class="flex-column align-items-start">
        <b-card-title>{{$t('schools')}}</b-card-title>
        <b-card-text class="text-muted mt-25">
          {{$t('dash.cards.go_to')}}
          <b-link
          to="schools">
            {{$t('schools')}}
          </b-link> {{$t('dash.cards.to_apply_filters')}}
        </b-card-text>
      </b-card-header>
      <paginated-table
        :data="schools"
        ability="Schools"
        link='school'
        :canDelete="true"
        :canRecover="true"
        :canSelect="false"
        :loading_data="loading"
      ></paginated-table>
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
  </b-card>
</template>

<script>
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import { BCard, BCardHeader, BCardTitle, BCardText, BLink, BSpinner } from 'bootstrap-vue'
export default {
  components:{
    PaginatedTable,
    BCard, BCardHeader, BCardTitle, BCardText, BLink, BSpinner
  },
  data(){
    return {
      schools: [],
      loading: false
    }
  },
  created(){
    this.getSchools()
  },
  methods:{
    async getSchools(){
      this.loading = true
      await this.$http.post('dashboard/schools-by-partner-id',{}).then(response => {
        this.schools = response.data.schools
        this.loading = false
      }) 
    }
  }
}
</script>

<style>

</style>