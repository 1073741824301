<template>
  <section id="dashboard-analytics" >
    <admin-dash v-if="userData.role == 'admin'" />
    <support-dash v-if="userData.role == 'support'" />
    <partner-dash v-if="userData.role == 'partner'" />
    <school-admin-dash v-if="userData.role == 'school-admin'" />
  </section>
</template>

<script>
import AdminDash from './AdminDash.vue'
import SupportDash from './SupportDash.vue'
import PartnerDash from './PartnerDash.vue'
import SchoolAdminDash from './SchoolAdminDash.vue'
export default {
  components:{
    AdminDash,
    PartnerDash,
    SupportDash,
    SchoolAdminDash
  },
  data(){
    return {
      userData: this.$store.getters['user/getUserData']
    }
  }
}
</script>

<style>

</style>