<template>
  <div>
    <template v-if="showCurrent" >
      <statistic-card-vertical
        v-if="!loading"
        color="success"
        icon="AwardIcon"
        :statistic="current_version"
        :statistic-title="$t('dash.cards.current_version')"
      />
      <b-card v-else>
        <div class="text-center mt-2 mb-2">
          <b-spinner variant="primary" label="Text Centered" />
        </div>
      </b-card>
    </template>

    <b-card class="card-congratulation-medal" v-if="showNext">
    <template v-if="!loading">

      <b-card-title>{{$t('dash.cards.next_release')}}</b-card-title>
    
      <h1 class="mb-75 extra-big">
        <b-link>{{ next_release.code}}</b-link>
      </h1>
      <b-card-text class="font-small-3">
        {{new Date(next_release.updated_at).toLocaleDateString() }} - 
        {{getTimeAgoOrRemaining(next_release.updated_at) == 'future'?
          $t('dash.cards.next_release_future', {days:getTimePassed(next_release.updated_at)})
        :
          $t('dash.cards.next_release_past', {days:getTimePassed(next_release.updated_at)})

        }}
        
      </b-card-text>
      <b-img
        :src="require('@/assets/images/illustration/badge.svg')"
        class="congratulation-medal"
        alt="Medal Pic"
      />
    </template>
    <div v-else class="text-center mt-2 mb-2">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    </b-card>

    <b-card
    v-if="showLatests"
      no-body
    >
      <template v-if="!loading">

        <b-card-header>
          <b-card-title>{{$t('dash.cards.latest_releases')}}</b-card-title>
        </b-card-header>
        <b-table
          responsive="sm"
          :items="latest_versions"
          :fields="tableColumns"
        />
      </template>
      <div v-else class="text-center mt-2 mb-2">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
    </b-card>
  </div>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import {BSpinner, BCard, BCardTitle, BCardText, BImg, BCardHeader, BTable, BLink } from 'bootstrap-vue'
export default {
  components:{
    StatisticCardVertical,
    BSpinner, BCard, BCardTitle, BCardText, BImg, BCardHeader, BTable, BLink
  },
  props:{
    showCurrent:{
      type:Boolean,
      default: false
    },
    showLatests:{
      type:Boolean,
      default: false
    },
    showNext:{
      type:Boolean,
      default: false
    },
  },
  data(){
    return {
      current_version: '',
      next_release: {},
      latest_versions: [],
      loading: false,
      tableColumns: [
        { key: 'code', label: this.$t('dash.cards.version') },
        { key: 'updated_at', label: this.$t('dash.cards.last_update') },
      ],
    }
  },
  mounted(){
    this.getVersionReleases()
  },
  methods:{
    getTimeAgoOrRemaining(updated_at){
      const firstDate = new Date(); //now
      const secondDate = new Date(updated_at);
      if(firstDate > secondDate){
        return "past"
      } else {
        return "future"
      }
    },
    getTimePassed(updated_at){
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(); //now
      const secondDate = new Date(updated_at);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays
    },
    async getVersionReleases(){
      this.loading = true
      await this.$http.post('dashboard/versionReleases',{}).then(response => {
        //console.log("--------------------", response.data)
        this.current_version = response.data.current_version;
        this.next_release = response.data.next_release;
        this.latest_versions = response.data.latest_versions;
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>