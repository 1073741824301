<template>
  <section id="dashboard-analytics"  >
    <b-row class="match-height">
      <b-col
        lg="4"
        md="12"
      >
        <welcome-card />
      </b-col>
      <b-col
        lg="8"
        md="12"
      >
        <statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="4"
        md="6"
      >
        <contents-states />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <version-releases
          :showCurrent="false"
          :showLatests="true"
          :showNext="true"
         />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <transactions />
      </b-col>

          <b-col
            lg="12"
            md="6"
          >
            <b-row class="match-height">
              <b-col
                lg="3"
                sm="12"
              >
                <statistic-card-horizontal
                  icon="UsersIcon"
                  statistic="86"
                  statistic-title="Usuarios Conectados"
                />
              </b-col>
              <b-col
                lg="3"
                sm="12"
              >
                <statistic-card-horizontal
                  icon="CpuIcon"
                  statistic="86%"
                  statistic-title="CPU Usage"
                />
              </b-col>
              <b-col
                lg="3"
                sm="12"
              >
                <statistic-card-horizontal
                  icon="CpuIcon"
                  statistic="86%"
                  statistic-title="CPU Usage"
                />
              </b-col>
              <b-col
                lg="3"
                sm="12"
              >
                <statistic-card-horizontal
                  icon="CpuIcon"
                  statistic="86%"
                  statistic-title="CPU Usage"
                />
              </b-col>
            </b-row>
          </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import WelcomeCard from './cards/WelcomeCard.vue'
import Statistics from './cards/Statistics.vue'
import ContentsStates from './cards/ContentsStates.vue'
import VersionReleases from './cards/VersionReleases.vue'
import Transactions from './cards/Transactions.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
components:{
    WelcomeCard,
    Statistics,
    ContentsStates,
    VersionReleases,
    Transactions,

    StatisticCardHorizontal,


    BRow, BCol
  },
}
</script>

<style>

</style>